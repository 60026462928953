.image-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.image-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-dim-gradient {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}

.small-font {
  font-size: xx-small;
}
