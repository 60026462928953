/* index.css or App.css */
.gallery-styles {
  box-sizing: border-box;
  user-select: none;

  margin: 0;
  padding: 0;
  overflow: hidden;

  width: 100vw;
  height: 100vh;
}
