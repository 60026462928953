$white: #fff !default;
$gray-100: #f3f3f3 !default;
$gray-200: #f3f3f3 !default;
$gray-300: #bebebe !default;
$gray-400: #bebebe !default;
$gray-500: #8a8a8a !default;
$gray-600: #8a8a8a !default;
$gray-700: #656565 !default;
$gray-800: #656565 !default;
$gray-900: #1a1a1a !default;

$gray: $gray-500;
$gray-dark: $gray-700;

$black: #000 !default;

$border-color: $gray-300;

$primary: #fad2ce;
$secondary: #fcca22;
$light: $gray-100;
$dark: $gray-900;
$success: #90e821;
$info: #c6dfff;
$warning: #e2ffbd;
$danger: #ec6869;
:root {
  --bs-btn-color: $white;
}

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark
);
// scss-docs-start gray-color-variables

.text-gray {
  color: $gray-dark !important;
}
